<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Destinos Ecopetrol</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Destinos Ecopetrol</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-11"></div>
                    <div class="col-md-1">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target="#modal-form-destinosEcopetrol"
                          @click="abrirModal('Crear', null)"
                          v-if="
                            $store.getters.can('admin.destinoEcopetrol.create')
                          "
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                        <!--                         <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_destinoEcopetrol_export"
                        v-if="$store.getters.can('admin.destinoEcopetrol.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body p-0">
                  <table
                    id="users"
                    class="table table-bordered table-striped table-hover table-sm"
                  >
                    <thead>
                      <tr>
                        <th>
                          ID
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="filtros.id"
                            @keyup="getIndex()"
                          />
                        </th>
                        <th>
                          Nombre
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="filtros.nombre"
                            @keyup="getIndex()"
                          />
                        </th>
                        <th>
                          Codigo
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="filtros.codigo"
                            @keyup="getIndex()"
                          />
                        </th>
                        <th>
                          Departamento
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="filtros.departamento"
                            label="descripcion"
                            :options="listasForms.departamentos"
                            @input="getIndex()"
                          ></v-select>
                        </th>
                        <th>
                          Ciudad
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="filtros.ciudad"
                            label="nombre"
                            :options="listasForms.ciudades"
                            @input="getIndex()"
                          ></v-select>
                        </th>
                        <th>
                          Estado
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.estado"
                            @change="getIndex()"
                          >
                            <option value="">Todos...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </th>
                        <th>Accion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in sitiosRegalias.data" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.codigo }}</td>
                        <td>{{ item.departamento.descripcion }}</td>
                        <td>{{ item.ciudad.nombre }}</td>

                        <td class="text-center">
                          <span
                            class="badge"
                            v-bind:class="[
                              item.estado == 1
                                ? 'badge-success'
                                : 'badge-danger',
                            ]"
                          >
                            {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                          </span>
                        </td>

                        <td style="width: 50px">
                          <div class="btn-group float-right">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#modal-form-destinosEcopetrol"
                              v-if="
                                $store.getters.can(
                                  'admin.destinoEcopetrol.edit'
                                )
                              "
                              @click="abrirModal('Editar', item.id)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="sitiosRegalias.total">
                    <p>
                      Mostrando del <b>{{ sitiosRegalias.from }}</b> al
                      <b>{{ sitiosRegalias.to }}</b> de un total:
                      <b>{{ sitiosRegalias.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="sitiosRegalias"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-form-destinosEcopetrol"
      @hidden="limpiarObjDestinoEcopetrol"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiarObjDestinoEcopetrol()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <input
                type="text"
                id="nombre"
                class="form-control form-control-sm"
                v-model="objDestinoEcopetrol.nombre"
                :class="{
                  'is-invalid':
                    $v.objDestinoEcopetrol.nombre.$invalid || nombreExistente,
                  'is-valid':
                    !$v.objDestinoEcopetrol.nombre.$invalid && !nombreExistente,
                }"
                @blur="checkNombreExistente"
              />
              <div class="error" v-if="nombreExistente">
                El nombre es obligatorio
              </div>
            </div>
            <div class="form-group">
              <label for="codigo">Código</label>
              <input
                type="text"
                id="codigo"
                class="form-control form-control-sm"
                v-model="objDestinoEcopetrol.codigo"
                :class="{
                  'is-invalid': $v.objDestinoEcopetrol.codigo.$invalid,
                  'is-valid': !$v.objDestinoEcopetrol.codigo.$invalid,
                }"
              />
              <div class="error" v-if="!$v.objDestinoEcopetrol.codigo.required">
                El código es obligatorio
              </div>
            </div>
            <div class="form-group">
              <label for="direccion">Dirección</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="objDestinoEcopetrol.direccion"
                :class="
                  $v.objDestinoEcopetrol.direccion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              />
            </div>
            <div class="form-group">
              <label for="departamento_id">Departamento</label>
              <v-select
                v-model="departamento"
                :options="listasForms.departamentos"
                label="descripcion"
                class="form-control form-control-sm p-0"
                :error-messages="
                  !$v.objDestinoEcopetrol.departamento_id.required
                    ? ['Seleccione un departamento']
                    : []
                "
                :class="[
                  $v.objDestinoEcopetrol.departamento_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                @input="selectDepartamento()"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="ciudad_id">Ciudad</label>
              <v-select
                v-model="ciudadSeleccionada"
                :options="listasForms.ciudades"
                label="nombre"
                class="form-control form-control-sm p-0"
                :disabled="!departamento"
                :error-messages="
                  !$v.objDestinoEcopetrol.ciudad_id.required
                    ? ['Seleccione una ciudad']
                    : []
                "
                :class="[
                  $v.objDestinoEcopetrol.ciudad_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                ]"
                @input="selectSitio"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="despachado">Despachado A</label>
              <v-select
                v-model="despachado_a"
                :options="listasForms.despachados"
                label="descripcion"
                :class="[
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                  'form-control form-control-sm p-0',
                  $v.objDestinoEcopetrol.despachado_a.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                ]"
                @input="selectDespachado()"
              ></v-select>
            </div>
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="objDestinoEcopetrol.estado"
                :class="
                  $v.objDestinoEcopetrol.estado.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.objDestinoEcopetrol.estado.required">
                El estado es obligatorio
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              :disabled="$v.objDestinoEcopetrol.$invalid"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <DestinoEcopetrolExport ref="DestinoEcopetrolExport" />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import DestinoEcopetrolExport from "./DestinoEcopetrolExport";

export default {
  name: "DestinosEcopetrolIndex",
  components: {
    vSelect,
    pagination,
    Loading,
    DestinoEcopetrolExport,
  },
  data() {
    return {
      departamento: {},
      ciudadSeleccionada: {},
      sitiosRegalias: {},
      despachado_a: {},
      filtros: {
        id: null,
        nombre: null,
        departamento: null,
        ciudad: null,
        estado: null,
        codigo: null,
      },
      objDestinoEcopetrol: {
        departamento_id: null,
        ciudad_id: null,
        despachado_a: null,
        nombre: null,
        codigo: null,
        direccion: null,
        estado: null,
      },
      modal: {
        title: "",
        accion: "",
      },
      listasForms: {
        departamentos: [],
        ciudades: [],
        despachados: [],
        estados: [],
      },
      cargando: false,
      nombreExistente: false,
    };
  },
  validations: {
    objDestinoEcopetrol: {
      departamento_id: {
        required,
      },
      ciudad_id: {
        required,
      },
      nombre: {
        required,
      },
      codigo: {
        required,
      },
      direccion: {
        required,
      },
      despachado_a: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      this.page = page;
      await axios
        .get("/api/admin/destinosEcopetrol?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.sitiosRegalias = response.data;
          this.cargando = false;
        });
      this.cargando = false;
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getDepartamentos() {
      await axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },

    async getDespachado() {
      await axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados = response.data;
      });
    },

    async getCiudades(departamento_id) {
      try {
        const response = await axios.get("/api/admin/ciudades/lista", {
          params: { departamento_id: departamento_id },
        });
        this.listasForms.ciudades = response.data.filter(
          (ciudad) => ciudad.departamento_id === departamento_id
        );
      } catch (error) {
        console.error("Error al cargar las ciudades:", error);
      }
    },

    selectDepartamento() {
      this.objDestinoEcopetrol.ciudad_id = null;
      this.objDestinoEcopetrol.departamento_id = null;
      this.ciudadSeleccionada = {};
      if (this.departamento) {
        this.objDestinoEcopetrol.departamento_id = this.departamento.id;
        this.getCiudades(this.departamento.id);
      }
    },

    async abrirModal(accion, id) {
      this.modal.title = accion + " Destino Ecopetrol";
      if (accion === "Crear") {
        this.limpiarObjDestinoEcopetrol();
      } else if (accion === "Editar") {
        await this.show(id); // Esperar a que se carguen los datos del destino
      }
    },

    async show(idDestinoEcopetrol) {
      this.cargando = true;
      try {
        const response = await axios.get(
          `/api/admin/destinosEcopetrol/show/${idDestinoEcopetrol}`
        );
        this.objDestinoEcopetrol = response.data;

        // Cargar el departamento y las ciudades antes de asignar los valores
        this.departamento = this.listasForms.departamentos.find(
          (dept) => dept.id === this.objDestinoEcopetrol.departamento_id
        );

        await this.getCiudades(this.objDestinoEcopetrol.departamento_id); // Asegurarse de cargar las ciudades primero

        this.ciudadSeleccionada = this.listasForms.ciudades.find(
          (city) => city.id === this.objDestinoEcopetrol.ciudad_id
        );

        this.despachado_a = this.listasForms.despachados.find(
          (d) => d.numeracion == this.objDestinoEcopetrol.despachado_a
        );
        this.cargando = false;
      } catch (error) {
        console.error(
          "Error al cargar los datos del destino Ecopetrol:",
          error
        );
        this.cargando = false;
      }
      this.cargando = false;
    },

    selectSitio() {
      this.objDestinoEcopetrol.ciudad_id = null;
      if (this.ciudadSeleccionada) {
        this.objDestinoEcopetrol.ciudad_id = this.ciudadSeleccionada.id;
      }
    },

    selectDespachado() {
      this.objDestinoEcopetrol.despachado_a = null;
      if (this.despachado_a) {
        this.objDestinoEcopetrol.despachado_a = this.despachado_a.numeracion;
      }
    },

    actualizarCiudadSeleccionada(ciudad) {
      this.objDestinoEcopetrol.ciudad_id = ciudad ? ciudad.id : null;
    },

    limpiarObjDestinoEcopetrol() {
      this.objDestinoEcopetrol = {
        id: null,
        nombre: null,
        despachado_a: null,
        codigo: null,
        departamento_id: null,
        ciudad_id: null,
        direccion: null,
        estado: null,
      };
      this.departamento = {};
      this.ciudadSeleccionada = {};
      this.despachado_a = {};
      this.nombreExistente = false;
    },

    getItem(id) {
      axios
        .get(`/api/admin/destinosEcopetrol/show/${id}`)
        .then((response) => {
          this.objDestinoEcopetrol = response.data;

          this.getDepartamentos();
          this.getCiudades(response.data.departamento_id);

          this.departamento = this.listasForms.departamentos.find(
            (dep) => dep.id === response.data.departamento_id
          );

          // Asignar ciudadSeleccionada solo después de asegurarnos que las ciudades están cargadas
          this.ciudadSeleccionada = this.listasForms.ciudades.find(
            (ciudad) => ciudad.id === response.data.ciudad_id
          );
          this.cargando = false;
        })
        .catch((error) => {
          console.error("Error fetching item data:", error);
          this.cargando = false;
        });
    },

    save() {
      if (!this.$v.objDestinoEcopetrol.$invalid) {
        this.cargando = true;
        axios
          .post("/api/admin/destinosEcopetrol", this.objDestinoEcopetrol)
          .then((response) => {
            this.$refs.closeModal.click();
            this.objDestinoEcopetrol = {};
            this.departamento = null;
            this.ciudadSeleccionada = null;
            this.listasForms.ciudades = [];
            this.getIndex(this.page);
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    async checkNombreExistente() {
      // Si está editando y el nombre no ha cambiado, no realizar la verificación
      if (
        this.modal.action === "Editar" &&
        this.objDestinoEcopetrol.nombre ===
          this.sitiosRegalias.data.find(
            (item) => item.id === this.objDestinoEcopetrol.id
          )?.nombre
      ) {
        this.nombreExistente = false;
        return;
      }

      try {
        const { data } = await axios.get(
          "/api/admin/destinosEcopetrol/check-nombre",
          {
            params: { nombre: this.objDestinoEcopetrol.nombre },
          }
        );

        if (data.exists) {
          this.nombreExistente = true;
          this.objDestinoEcopetrol.nombre = "";
          this.$swal({
            icon: "error",
            title: "El sitio ya existe.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.nombreExistente = false;
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Ocurrió un error, vuelva a intentarlo..." + error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
  async mounted() {
    await this.getIndex();
    await this.getEstados();
    await this.getDepartamentos();
    await this.getDespachado();
  },
};
</script>
